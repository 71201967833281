import { FormattedCurrency } from '@bootstrap/utils/formatCurrency';
import { getFontColor } from '@connect/shared/utils/getFontColor';
import { Typography, TypographyType } from '@ui/typography';

export const renderAmount = ({
  amount,
  fontSize = TypographyType.BODY_S,
}: {
  amount?: number | null;
  fontSize?: TypographyType;
}): JSX.Element => {
  return (
    <Typography type={fontSize} color={getFontColor(amount ?? 0)} align="right">
      <FormattedCurrency value={amount ?? 0} />
    </Typography>
  );
};
